import { useEffect, useState } from 'react';

export default function useOnScreen(ref, threshold = 0) {

  const [isIntersecting, setIntersecting] = useState(true);
    
  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => setIntersecting(entry.isIntersecting),
      { threshold: threshold }
    );
    observer.observe(ref.current);
    return () => { observer.disconnect(); };
  }, []);
  
  return isIntersecting;
}