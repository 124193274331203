import { ReactNode } from 'react';
import ReactModal from 'react-modal';
import { ModalProvider } from 'react-modal-hook';

import Footer from 'components/footer/minimal';
import Head from 'components/globals/head';
import NavigationMinimal from 'components/navigation/minimal';

ReactModal.setAppElement('#___gatsby');

type LayoutMinimalProps = {
  children: ReactNode
} 

const Layout = ({ children }:LayoutMinimalProps) => {
  return (
    <div className="layout-wrapper">
      <Head />
      <ModalProvider>
        <NavigationMinimal data-section="navigation minimal" />
        <div role="main">{children}</div>
        <Footer backgroundColor="white" />
      </ModalProvider>
    </div>
  );
};

export default Layout;
