import { graphql, useStaticQuery } from 'gatsby';

import Layout from 'layouts/minimal';

import Yoast from 'components/globals/yoast';
import { HeroDefaultSignup } from 'components/hero/default-signup';

import './styles/schedule-demo.scss';

export default function ScheduleDemo() {
  const data = useStaticQuery(graphql`
    {
      allWordpressPage(filter: { slug: { eq: "schedule-demo" } }) {
        edges {
          node {
            wordpress_id
        yoast_head_json {
          title
          description
          canonical
          og_title
          og_description
          og_image {
            url {
              source_url
            }
          }
          og_url
          og_type
          twitter_title
          twitter_description
          twitter_image {
            source_url
          }
        }
            acf {
              hero {
                copy
                title
                background_desktop_image {
                  source_url
                }
                background_mobile_image {
                  source_url
                }
                schedule_demo {
                  button_text
                }
                extra_content
              }
            }
          }
        }
      }
    }
  `);

  const pageContent = data.allWordpressPage.edges[0].node.acf;
  const hero = pageContent.hero;
  const yoast = data.allWordpressPage.edges[0].node.yoast_head_json;

  return (
    <Layout>
      <Yoast { ...yoast } />
      <div className="schedule-demo-page" data-signal={ data.allWordpressPage.edges[0].node.wordpress_id }>
        <HeroDefaultSignup
          { ...hero }
          scheduleDemoClassName="button is-primary is-rounded has-text-weight-semibold intercom-launch-demo"
        />
      </div>
    </Layout>
  );
}
