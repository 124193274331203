import Link from 'components/link/link';

import './styles/minimal.scss';

const NavigationMinimal = () => (
  <nav className="minimal-navigation">
    <Link to="/">
      <img
        src="https://marketing-assets.wheniwork-production.com/2019/08/15163825/logo.svg"
        alt="fill me out"
      />
    </Link>
  </nav>
);

export default NavigationMinimal;
